import './App.css';
import Login from "./pages/login";
import Logout from "./pages/logout";
import Register from "./pages/register";
import PasswordReset from "./pages/password_reset";
import PasswordResetRequest from "./pages/password_reset_request";
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import PrivateRoute from "./components/auth/PrivateRoute";
import FourOhFour from "./pages/404";
// import { useAuth } from "./components/auth/AuthProvider";
import Home from "./components/home/home";
import Profile from "./components/profile/profile";
import Uploader from "./components/upload/uploader";
import * as React from "react";
import { useState } from "react";
import { ThemeProvider, CssBaseline } from '@mui/material';
import { theme } from './components/theme/theme';
import RegularPage from "./pages/RegularPage";

function App() {
    // const auth = useAuth()
    const [navSelection, setNavSelection] = useState<string>('search');


    return (
        <div className="App">
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <BrowserRouter>
                    <Routes>
                        <Route path="/" element=<Home /> />
                        {/* <Route path="/" element={auth.user ? <Navigate to="/" /> : <Home />} /> */}
                        {/* <Route path="/login" element={auth.user ? <Navigate to="/" /> : <Login />} />
                        <Route path="/register" element={auth.user ? <Navigate to="/" /> : <Register />} /> */}
                        {/* <Route path="/password/reset" element={auth.user ? <Navigate to="/" /> : <PasswordReset />} />
                        <Route path="/password/reset/request" element={auth.user ? <Navigate to="/" /> : <PasswordResetRequest />} /> */}
                        {/* <Route element={<PrivateRoute />}>
                            <Route
                                element={<RegularPage navSelection={navSelection}
                                    setNavSelection={setNavSelection} />}>
                                <Route path="/logout" element={<Logout />} />
                                <Route path="/profile" element={<Profile />} />
                                <Route path="/upload" element={<Uploader />} />
                            </Route>
                        </Route> */}
                        <Route path="*" element={<FourOhFour />} />
                    </Routes>
                </BrowserRouter>
            </ThemeProvider>
        </div>
    );
}

export default App;
