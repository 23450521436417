import React, { useState } from 'react';
import {
    Card,
    Typography,
    TextField,
    FormControl,
    Button,
    Box,
    Modal,
} from '@mui/material';
import LoadingWheel from 'src/components/theme/loading';
import axios from 'axios';
import { FaHeart } from 'react-icons/fa';
import { useTheme } from "@mui/material/styles";

interface EmailSignupModalProps {
    modalOpen: boolean;
    handleClose: () => void;
}

export default function EmailSignupModal(props: EmailSignupModalProps) {
    const theme = useTheme();
    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [company, setCompany] = useState('');
    const [emailErrorMessage, setEmailErrorMessage] = useState<string | null>(null);
    const [generalErrorMessage, setGeneralErrorMessage] = useState<string | null>(null);
    const [nameErrorMessage, setNameErrorMessage] = useState<string | null>(null);
    const [submitDisabled, setSubmitDisabled] = useState(false);
    const [emailDisplay, setEmailDisplay] = useState(true);
    const [completeDisplay, setCompleteDisplay] = useState(false);

    const submitEmail = () => {
        setSubmitDisabled(true);

        if (!name) {
            setNameErrorMessage('Please enter your name.');
            setSubmitDisabled(false);
            return;
        }

        if (!email || !/\S+@\S+\.\S+/.test(email)) {
            setEmailErrorMessage('Please enter a valid email address.');
            setSubmitDisabled(false);
            return;
        }

        setEmailErrorMessage(null);

        axios
            .post(
                'https://api.mailgun.net/v3/mail.clover.realty/messages',
                new URLSearchParams({
                    from: 'waiting_list@mail.clover.realty',
                    to: 'daniel@orgatlas.org',
                    subject: 'Clover Registration',
                    text: "New Clover Registration\nName: " + name + '\nEmail: ' + email + '\nCompany: ' + company,
                }),
                {
                    auth: {
                        username: 'api',
                        password: process.env.REACT_APP_MAILGUN_API_KEY ?? '',
                    },
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                    },
                }
            )
            .then(() => {
                setEmailDisplay(false);
                setCompleteDisplay(true);
            })
            .catch((error) => {
                console.error(error);
                const errorMessage =
                    error.response?.data?.error ||
                    'Something went wrong. Please try again later.';
                setGeneralErrorMessage(errorMessage);
                setSubmitDisabled(false);
            });
    };

    return (
        <Modal
            open={props.modalOpen}
            onClose={props.handleClose}
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                border: 'none',
                backdropFilter: 'blur(5px)'
            }}
        >
            <Box
                onClick={(e) => e.stopPropagation()} // Prevents modal close when clicking inside
            >
                <Card>
                    {emailDisplay && (
                        <>
                            <Typography variant="h4" gutterBottom color="text.primary">
                                Join our early access list:
                            </Typography>

                            <FormControl fullWidth margin="normal">
                                <label style={{ fontSize: '16pt' }}>Name</label>
                                <TextField
                                    variant="outlined"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                    fullWidth
                                    error={!!nameErrorMessage}
                                    helperText={nameErrorMessage}
                                />
                            </FormControl>

                            <FormControl fullWidth margin="normal">
                                <label style={{ fontSize: '16pt' }}>Email</label>
                                <TextField
                                    variant="outlined"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    error={!!emailErrorMessage}
                                    helperText={emailErrorMessage}
                                    fullWidth
                                />
                            </FormControl>

                            <FormControl fullWidth margin="normal">
                                <label style={{ fontSize: '16pt' }}>Company</label>
                                <TextField
                                    variant="outlined"
                                    value={company}
                                    onChange={(e) => setCompany(e.target.value)}
                                    fullWidth
                                />
                            </FormControl>

                            <Typography variant='h6' color='error.main'>{generalErrorMessage}</Typography>

                            <Button
                                variant="contained"
                                color="secondary"
                                onClick={submitEmail}
                                disabled={submitDisabled}
                                fullWidth
                                sx={{ mt: 3, mb: 2 }}
                            >
                                {submitDisabled ? <LoadingWheel /> : 'Sign Up'}
                            </Button>
                        </>
                    )}

                    {completeDisplay && (
                        <Box sx={{ textAlign: 'center' }}>
                            <Typography variant="h2" color="text.primary" sx={{ mt: 2 }}>
                                Thank you <FaHeart style={{ color: theme.palette.error.main }} />
                            </Typography>
                            <Typography variant="h5" color="text.primary" sx={{ mt: 2, fontSize: '24pt' }}>
                                When Clover is ready to use, you'll be the first to know
                            </Typography>
                        </Box>
                    )}
                </Card>
            </Box>
        </Modal>
    );
}
