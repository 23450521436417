import * as React from 'react';
import { Card, Typography, TextField, FormControl, Button, Box } from '@mui/material'
import { useTheme } from "@mui/material/styles";
import { useEffect, useState } from "react";
import Navbar from './navbar'
import { useLocation } from 'react-router-dom';
import About from './about';
import Logo from 'src/assets/Clover Logo.png'
import EmailSignupModal from './EmailSignupModal';

export default function Home() {

    const [modalOpen, setModalOpen] = useState(false);
    const theme = useTheme();

    const location = useLocation();

    useEffect(() => {
        if (location.hash) {
            const elementId = location.hash.substring(1);
            const element = document.getElementById(elementId);
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, [location]);

    const handleOpen = () => setModalOpen(true);
    const handleClose = () => setModalOpen(false);



    return (
        <Box sx={{ 'backgroundColor': theme.palette.background.default }} id={'home'}>

            <Navbar />
            <Box
                sx={{
                    justifyContent: "center",
                    alignItems: "center",
                    mt: '0',
                    color: theme.palette.text.primary,
                    textAlign: 'center',
                    minHeight: '100vh'
                }}>

                <Box sx={{ height: { md: '30vh', sm: '30vh', xs: '20vh' } }}>
                    <img src={Logo} style={{ height: '100%' }} />
                </Box>

                <Typography variant="h1" component="h2" sx={{ mb: 2, fontSize: { md: '50pt', sm: '24pt', xs: '24pt' } }}>
                    Property condition reports. <span style={{ color: theme.palette.primary.dark }}>Fast.</span>
                </Typography>

                <Button
                    onClick={handleOpen}
                    variant="contained"
                    sx={{ maxWidth: '70%', mt: 4, mb: 2, p: 4 }}
                >
                    <Typography variant='h4' sx={{ fontSize: { md: '32pt', sm: '18pt', xs: '18pt' }, color: theme.palette.text.secondary }}>
                        Join the priority access list
                    </Typography>
                </Button>
                <Box>
                    <a href='/#about' style={{ textDecoration: 'none', color: theme.palette.primary.dark }}> ...or click here to learn more</a>
                </Box>

            </Box>

            <EmailSignupModal
                modalOpen={modalOpen}
                handleClose={handleClose}
            />

            <About />
        </Box>
    );
}