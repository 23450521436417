import React from 'react';
import { useNavigate } from 'react-router-dom';
import NotFoundImage from '../assets/Clover Logo.svg'

const NotFoundPage = () => {
    const navigate = useNavigate();

    const goHome = () => {
        navigate(-1);
    };

    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100vh',
            backgroundColor: '#f9f9f9',
            textAlign: 'center',
            padding: '20px'
        }}>
            <img
                src={NotFoundImage}
                alt="404 - Not Found"
                style={{
                    width: '300px',
                    marginBottom: '20px'
                }}
            />
            <h1 style={{
                fontSize: '72px',
                fontWeight: 'bold',
                color: '#333'
            }}>
                404
            </h1>
            <h2 style={{
                fontSize: '24px',
                color: '#666',
                margin: '10px 0'
            }}>
                Page Not Found
            </h2>
            <p style={{
                fontSize: '16px',
                color: '#999',
                marginBottom: '20px'
            }}>
                The page you're looking for doesn't exist.
            </p>
            <button
                onClick={goHome}
                style={{
                    padding: '10px 20px',
                    fontSize: '16px',
                    color: '#fff',
                    backgroundColor: '#007BFF',
                    border: 'none',
                    borderRadius: '5px',
                    cursor: 'pointer',
                    transition: 'background-color 0.3s',
                }}
                onMouseOver={(e) => e.currentTarget.style.backgroundColor = '#0056b3'}
                onMouseOut={(e) => e.currentTarget.style.backgroundColor = '#007BFF'}
            >
                Go Back
            </button>
        </div>
    );
};

export default NotFoundPage;
