import { Box, Grid2, Typography, Button } from "@mui/material";
import * as React from "react";
import { Link } from 'react-router-dom';
import { useTheme } from "@mui/material/styles";
import { useEffect, useState } from "react";
import EmailSignupModal from "./EmailSignupModal";

interface NavLinkProps {
    to: string;
    title: string;
    selected: string;
    setSelected: Function;
}

const NavLink = (props: NavLinkProps) => {
    const theme = useTheme();

    return (
        <Grid2 sx={{ marginLeft: '60px' }}>
            <Link
                to={props.to}
                style={{
                    textDecoration: 'none',
                    cursor: 'pointer',
                    color: props.selected === props.title ? theme.palette.primary.main : theme.palette.text.primary,
                    transition: 'color 0.8s',
                }}
                onMouseEnter={(e) => {
                    e.currentTarget.style.color = theme.palette.primary.main;
                }}
                onMouseLeave={(e) => {
                    e.currentTarget.style.color = props.selected === props.title ? theme.palette.primary.main : theme.palette.text.primary;
                }}
                onClick={() => {
                    props.setSelected(props.title)
                }}
            >
                <Typography variant="h4" sx={{ fontSize: { md: '24pt', sm: '16pt' } }}>
                    {props.title}
                </Typography>
            </Link>
        </Grid2>
    );
};

const PageLink = (props: NavLinkProps) => {
    const theme = useTheme();

    return (
        <Grid2 sx={{ marginLeft: '60px' }}>
            <a
                href={props.to}
                style={{
                    textDecoration: 'none',
                    cursor: 'pointer',
                    color: props.selected === props.title ? theme.palette.primary.main : theme.palette.text.primary,
                    transition: 'color 0.8s',
                }}
                onMouseEnter={(e) => {
                    e.currentTarget.style.color = theme.palette.primary.main;
                }}
                onMouseLeave={(e) => {
                    e.currentTarget.style.color = props.selected === props.title ? theme.palette.primary.main : theme.palette.text.primary;
                }}
                onClick={() => {
                    props.setSelected(props.title)
                }}
            >
                <Typography variant="h4" sx={{ fontSize: { md: '24pt', sm: '16pt' } }}>
                    {props.title}
                </Typography>
            </a>
        </Grid2 >
    );
};

export default function Navbar() {
    const [scrolled, setScrolled] = useState(false);
    const [selected, setSelected] = useState('Home');
    const [modalOpen, setModalOpen] = useState(false);
    const handleOpen = () => setModalOpen(true);
    const handleClose = () => setModalOpen(false);
    const theme = useTheme();

    useEffect(() => {
        const handleScroll = () => {
            setScrolled(window.scrollY > 0);
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);


    return (
        <>
            <Grid2
                container
                sx={{
                    padding: '20px',
                    alignItems: 'center',
                    position: 'sticky',
                    top: 0,
                    backgroundColor: theme.palette.background.default,
                    boxShadow: scrolled ? '0px 4px 10px rgba(0, 0, 0, 0.1)' : 'none',
                    zIndex: 1000,
                }}
            >
                <Grid2 size={1}></Grid2>
                <Grid2 size={3}>
                    <Box
                        sx={{
                            height: '80px',
                            maxWidth: '60%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            padding: '8px',
                        }}
                    >
                    </Box>
                </Grid2>
                <Grid2 size={7} container sx={{ justifyContent: 'flex-end' }}>
                    <NavLink to={'/#home'} title={'Home'} selected={selected} setSelected={setSelected} />
                    <PageLink to={'/#about'} title={'About'} selected={selected} setSelected={setSelected} />
                    <a
                        onClick={handleOpen}

                    ><NavLink to={'#'} title={'Early Access'} selected={selected} setSelected={setSelected} /></a>
                </Grid2>
                <Grid2 size={1}></Grid2>
            </Grid2>
            <EmailSignupModal
                modalOpen={modalOpen}
                handleClose={handleClose}
            />
        </>
    )
}