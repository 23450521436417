import * as React from 'react';
import { Card, Modal, Typography, TextField, FormControl, FormLabel, Button, Box, Grid2 } from '@mui/material'
import { useTheme } from "@mui/material/styles";

export default function About() {
    const theme = useTheme();

    return (
        <Box minHeight={'100vh'}
            id={'about'}
            sx={{
                'backgroundColor': theme.palette.background.default, display: 'flex', position: 'relative', alignItems: 'center', justifyContent: 'center'
            }}>
            <Box
                sx={{
                    maxWidth: '70%',
                    margin: '0 auto',
                    pt: '130px'
                }}>

                <Typography variant="h3" component="h2"
                    sx={{ marginBottom: 2, color: theme.palette.text.primary, fontSize: { md: '36pt', sm: '24pt' } }}>
                    What is <span style={{ color: theme.palette.primary.dark }}>Clover</span>?
                </Typography>
                <Typography variant="body1" component="h2"
                    sx={{ marginBottom: 2, color: theme.palette.text.primary, fontSize: { md: '24pt', sm: '16pt' }, fontWeight: 100 }}>
                    Preparing a property condition report is a tedious task.
                </Typography>
                <Typography variant="body1" component="h2"
                    sx={{ marginBottom: 2, color: theme.palette.text.primary, fontSize: { md: '24pt', sm: '16pt' }, fontWeight: 100 }}>
                    With Clover, you simply take some photos of the property and upload them. Clover will produce a property condition report for you in a few seconds!.
                </Typography>

                <Typography variant="body1" component="h2"
                    sx={{ marginBottom: 2, color: theme.palette.text.primary, fontSize: { md: '24pt', sm: '16pt' }, fontWeight: 100 }}>
                    You can revise, store and send the report direct from Clover.
                </Typography>


            </Box>
        </Box>
    )
        ;
}